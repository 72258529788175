import { Segment, SegmentData } from '@/types';
import { createObjectWithId } from './createObjectWithId';
import { OBJECT_METHOD } from '@/constants';

interface SegmentShape {
  from: string;
  to: string;
  type: string;
}

export const isSameSegment = (
  segment1: SegmentShape,
  segment2: SegmentShape
) => {
  return (
    segment1.from === segment2.from &&
    segment1.to === segment2.to &&
    segment1.type === segment2.type
  );
};

export const createSegment = (segmentData: SegmentData): Segment => {
  return createObjectWithId({
    ...segmentData,
    _method: OBJECT_METHOD.CREATE,
  });
};

export const getSegment = (
  segments: Array<Segment>,
  segment: SegmentData
): Segment | undefined => {
  return segments.find(
    (existing) =>
      isSameSegment(existing, segment) &&
      existing._method !== OBJECT_METHOD.DESTROY
  );
};

export const updateSegment = (segments: Array<Segment>, segment: Segment) => {
  return segments.map((existingSegment) => {
    if (existingSegment.id === segment.id) {
      return {
        ...existingSegment,
        ...segment,
        _method:
          existingSegment._method === OBJECT_METHOD.CREATE
            ? OBJECT_METHOD.CREATE
            : OBJECT_METHOD.UPDATE,
      };
    } else {
      return existingSegment;
    }
  });
};

export const removeSegment = (segments: Array<Segment>, segment: Segment) => {
  return segments
    .map((existingSegment) => {
      if (existingSegment.id === segment.id) {
        return existingSegment._method === OBJECT_METHOD.CREATE
          ? null
          : {
              ...existingSegment,
              _method: OBJECT_METHOD.DESTROY,
            };
      } else {
        return existingSegment;
      }
    })
    .filter(Boolean) as Array<Segment>;
};
