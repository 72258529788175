import { createObjectWithId } from '../createObjectWithId';
import { isCrateItem } from './isCrateItem';
import { isCartonItem } from './isCartonItem';
import { Item, ItemData, ItemPreview } from '@/types';
import { areItemsTheSame } from '../areItemsTheSame';
import { DEFAULT_CRATE_CUBE, OBJECT_METHOD } from '@/constants';

const findItemInList = (items: Array<Item>, data: ItemPreview) =>
  items.find(
    (item) =>
      item._method !== OBJECT_METHOD.DESTROY && areItemsTheSame(item, data)
  );

const isPackedItem = (itemData: ItemData) => {
  return isCartonItem(itemData) || isCrateItem(itemData);
};

const destroyItem = (item: Item) => {
  return {
    ...item,
    _method: OBJECT_METHOD.DESTROY,
  };
};

const createPreviewItem = (data: ItemData) => {
  return {
    ...data,
    weight: data.weight,
    cube: isCrateItem(data) ? DEFAULT_CRATE_CUBE : data.cube,
    length: null,
    width: null,
    height: null,
    packing: isPackedItem(data) ? data.going : 0,
    unpacking: isPackedItem(data) ? data.going : 0,
    comments: '',
    images: [],
  };
};

const updateItemWithPreview = (
  items: Array<Item>,
  existingId: string,
  itemPreview: ItemPreview
) => {
  return items.map((item) => {
    if (item.id === existingId) {
      const going = item.going + itemPreview.going;

      return {
        ...item,
        going,
        notGoing: item.notGoing + itemPreview.notGoing,
        packing: isPackedItem(item) ? going : 0,
        unpacking: isPackedItem(item) ? going : 0,
        _method:
          item._method === OBJECT_METHOD.CREATE
            ? OBJECT_METHOD.CREATE
            : OBJECT_METHOD.UPDATE,
      };
    }

    return item;
  });
};

const createItemWithPreview = (
  items: Array<Item>,
  itemPreview: ItemPreview
): Array<Item> => {
  return [
    ...items,
    {
      ...createObjectWithId(itemPreview),
      _method: OBJECT_METHOD.CREATE,
    },
  ];
};

export const upsertItemWithItemDefinition = (
  items: Array<Item>,
  data: ItemData
): Array<Item> => {
  const itemPreview = createPreviewItem(data);
  const existingItem = findItemInList(items, itemPreview);

  return existingItem
    ? updateItemWithPreview(items, existingItem.id, itemPreview)
    : createItemWithPreview(items, itemPreview);
};

export const removeItem = (items: Array<Item>, item: Item) => {
  return items
    .map((existingItem) => {
      if (existingItem.id === item.id) {
        return existingItem._method === OBJECT_METHOD.CREATE
          ? null
          : destroyItem(existingItem);
      } else {
        return existingItem;
      }
    })
    .filter(Boolean) as Array<Item>;
};

export const updateItem = (itemsList: Array<Item>, itemToUpdate: Item) => {
  return itemsList.map((existingItem) =>
    existingItem.id === itemToUpdate.id
      ? {
          ...existingItem,
          ...itemToUpdate,
          _method:
            existingItem._method === OBJECT_METHOD.CREATE
              ? OBJECT_METHOD.CREATE
              : OBJECT_METHOD.UPDATE,
        }
      : existingItem
  );
};

export const removeItemsInRoom = (itemsList: Array<Item>, roomId: string) => {
  return itemsList.map((existingItem) =>
    existingItem.roomId === roomId ? destroyItem(existingItem) : existingItem
  );
};

export const removeItemsInSegment = (
  itemsList: Array<Item>,
  segmentId: string
) => {
  return itemsList.map((existingItem) =>
    existingItem.segmentId === segmentId
      ? destroyItem(existingItem)
      : existingItem
  );
};
