import { Item } from '@/types/Item';
import { Room } from '@/types/Room';
import { Segment } from '@/types/Segment';
import { Survey } from '@/types/Survey';
import { sortByCreatedAt } from '../sortByCreatedAt';
import { SurveyService, SurveyServiceCompletionStatus } from '@/types';

type JsonApiRelationshipsData = {
  type: string;
  id: string;
};

interface JsonApiRelationships {
  [key: string]: {
    data?: JsonApiRelationshipsData | Array<JsonApiRelationshipsData>;
    meta?: {};
  };
}

interface JsonApiIncluded {
  id: string;
  type: string;
  attributes: {
    [key: string]: unknown;
    created_at?: number;
  };
  relationships?: {
    [key: string]: unknown;
  };
}

interface JsonApiSurveyAttributes {
  is_primary: boolean;
  is_complete: boolean;
  survey_type: string;
  name: string;
  order_id: string;
  service_id: string;
}

interface JsonApiSurveyResponseData {
  id: string;
  type: string;
  attributes: JsonApiSurveyAttributes;
  relationships: JsonApiRelationships;
}

interface SurveyJsonApiResponse {
  data: JsonApiSurveyResponseData;
  included: Array<JsonApiIncluded>;
  meta: {};
}

interface CreateTransformResponse {
  service: SurveyService;
  survey: Survey;
}

const mapSegment = ({ id, attributes }: JsonApiIncluded): Segment =>
  ({
    id,
    to: attributes.to,
    from: attributes.from,
    type: attributes.type,
  } as Segment);

const mapRoom = ({ id, attributes }: JsonApiIncluded): Room =>
  ({
    id,
    name: attributes.room_name,
    classification: attributes.classification,
    images: attributes.images,
  } as Room);

const mapItem = ({ id, attributes }: JsonApiIncluded) =>
  ({
    id,
    name: attributes.name,
    isoCode: attributes.iso_code,
    weight: attributes.weight,
    // TODO: Remove this parseFloat logic once API returns only decimals, not strings
    cube:
      typeof attributes.cube === 'string'
        ? parseFloat(attributes.cube)
        : attributes.cube,
    length: attributes.length,
    width: attributes.width,
    height: attributes.height,
    roomId: attributes.room_id,
    segmentId: attributes.segment_id,
    comments: attributes.comments,
    category: attributes.category,
    going: attributes.going,
    notGoing: attributes.not_going,
    packing: attributes.packing,
    unpacking: attributes.unpacking,
    images: attributes.images,
    definitionId: attributes.definitionId,
  } as Item);

const filterType =
  (filterType: string) =>
  ({ type }: { type: string }) =>
    type === filterType;
const filterSegment = filterType('segments');
const filterRoom = filterType('survey_rooms');
const filterItem = filterType('survey_items');
const filterService = filterType('services');

const ExtractAndMapSurveyService = (
  includedServices: Array<JsonApiIncluded>
): SurveyService => {
  // There should only ever be 1 service associated with a survey
  const service = includedServices[0];

  return {
    id: service.id,
    status: service.attributes.status as string,
    completionStatus: service.attributes
      .completion_status as SurveyServiceCompletionStatus,
    subtype: service.attributes.subtype as string,
  };
};

export function transformSaveSurveyResponse(
  response: SurveyJsonApiResponse
): CreateTransformResponse {
  const { data, included } = response;

  return {
    service: ExtractAndMapSurveyService(included.filter(filterService)),
    survey: {
      id: data.id,
      primary: data.attributes.is_primary,
      completed: data.attributes.is_complete,
      orderId: data.attributes.order_id,
      serviceId: data.attributes.service_id,
      surveyType: data.attributes.survey_type,
      segments: sortByCreatedAt(included.filter(filterSegment)).map(mapSegment),
      rooms: sortByCreatedAt(included.filter(filterRoom)).map(mapRoom),
      items: sortByCreatedAt(included.filter(filterItem)).map(mapItem),
    },
  };
}
