import { Typography, Menu, MenuItem } from '@mui/material';

interface RoomActionMenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onRemoveRoom?: () => void;
  onEditRoom?: () => void;
}

export function RoomActionMenu({
  anchorEl,
  open,
  onClose,
  onRemoveRoom,
  onEditRoom,
}: RoomActionMenuProps) {
  return (
    <>
      <Menu
        id="room-row-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-label': 'basic-button',
        }}
      >
        {onEditRoom ? (
          <MenuItem onClick={onEditRoom}>
            <Typography>Edit Room</Typography>
          </MenuItem>
        ) : null}
        {onRemoveRoom ? (
          <MenuItem onClick={onRemoveRoom}>
            <Typography color="error">Remove Room</Typography>
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}
