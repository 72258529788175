import { Image } from '@/types/Image';
import { Room } from '@/types/Room';
import { Segment } from '@/types/Segment';
import { Item, ItemCategory } from '@/types/Item';
import { SurveyServiceCompletionStatus } from '@/types/SurveyService';

interface SegmentResponse extends Segment {
  survey_id: string;
}

interface ImageResponse {
  id: string;
  url: string;
}

interface RoomResponse {
  classification: string;
  created_at: string;
  id: string;
  room_name: string;
  images: Array<ImageResponse>;
}

interface ItemResponse {
  id: string;
  name: string;
  iso_code: string | null;
  weight: number | null;
  // TODO: `cube` should not be a string, remove once fixed on backend
  cube: number | string;
  length: number | null;
  width: number | null;
  height: number | null;
  room: RoomResponse;
  room_id: string;
  segment_id: string;
  comments: string;
  category: ItemCategory;
  going: number;
  not_going: number;
  packing: number;
  unpacking: number;
  images: Array<ImageResponse>;
  survey_id: string;
  definition_id: number;
}

interface AddressResponse {
  street1: string;
  street2: string;
  region: string;
  locality: string;
  postal_code: string;
  country: string;
}

interface CustomerResponse {
  id: string;
  first_name: string;
  last_name: string;
}

interface OrderResponse {
  id: string;
  number: string;
  origin: AddressResponse;
  destination: AddressResponse;
  customer: CustomerResponse;
}

interface ServiceResponse {
  id: string;
  status: string;
  completion_status: SurveyServiceCompletionStatus;
  subtype?: string;
}

interface SurveyResponse {
  name: string;
  id: string;
  is_primary: boolean;
  is_complete: boolean;
  order: OrderResponse;
  order_id: string;
  service_id: string;
  segments: Array<SegmentResponse>;
  items: Array<ItemResponse>;
  rooms: Array<RoomResponse>;
  survey_type: string;
  service: ServiceResponse;
}

const mapSegment = ({ id, to, from, type }: Segment): Segment => ({
  id,
  to,
  from,
  type,
});

const mapImage = ({ id, url }: ImageResponse): Image => ({
  url,
});

const mapItem = (item: ItemResponse): Item => ({
  id: item.id,
  name: item.name,
  isoCode: item.iso_code,
  weight: item.weight,
  // TODO: Remove this parseFloat logic once API returns only decimals, not strings
  cube: typeof item.cube === 'string' ? parseFloat(item.cube) : item.cube,
  length: item.length,
  width: item.weight,
  height: item.height,
  roomId: item.room_id,
  segmentId: item.segment_id,
  comments: item.comments,
  category: item.category,
  going: item.going,
  notGoing: item.not_going,
  packing: item.packing,
  unpacking: item.unpacking,
  images: item.images.map(mapImage),
  definitionId: item.definition_id,
});

const mapRoom = ({
  classification,
  id,
  room_name,
  images,
}: RoomResponse): Room => ({
  classification,
  id,
  name: room_name,
  images: images.map(mapImage),
});

const transformAddress = (address: AddressResponse) => ({
  address1: address.street1,
  address2: address.street2,
  city: address.locality,
  state: address.region,
  postalCode: address.postal_code,
  country: address.country,
});

const transformOrder = ({
  id,
  number,
  origin,
  destination,
}: OrderResponse) => ({
  id,
  number,
  origin: transformAddress(origin),
  destination: transformAddress(destination),
});

const transformSuvery = (survey: SurveyResponse) => ({
  name: survey.name,
  id: survey.id,
  primary: survey.is_primary,
  completed: survey.is_complete,
  orderId: survey.order_id,
  serviceId: survey.service_id,
  segments: survey.segments.map(mapSegment),
  items: survey.items.map(mapItem),
  rooms: survey.rooms.map(mapRoom),
  surveyType: survey.survey_type,
});

const transformCustomer = ({
  id,
  first_name,
  last_name,
}: CustomerResponse) => ({
  id,
  firstName: first_name,
  lastName: last_name,
});

export const transformService = ({
  id,
  status,
  subtype,
  completion_status,
}: ServiceResponse) => ({
  id,
  status,
  subtype,
  completionStatus: completion_status,
});

export const transformResponse = (survey: SurveyResponse) => {
  return {
    order: transformOrder(survey.order),
    customer: transformCustomer(survey.order.customer),
    survey: transformSuvery(survey),
    service: transformService(survey.service),
  };
};
