interface Address {
  id: string;
  street1: string;
  street2: string | null;
  postal_code: string;
  region: string;
  locality: string;
  country: string;
}

interface Order {
  id: string;
  number: string;
  preferred_load_date: string;
  preferred_pack_date: string;
  preferred_delivery_date: string;
  origin: Address;
  destination: Address;
  customer: {
    id: string;
    first_name: string;
    last_name: string;
  };
}

const emptyAddress = {
  id: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  postalCode: '',
  country: '',
};

export const transformResponse = ({
  id,
  status,
  subtype,
  completion_status,
  order,
}: {
  id: string;
  status: string;
  subtype: string;
  completion_status: string;
  order: Order;
}) => ({
  service: {
    id,
    status,
    subtype,
    completionStatus: completion_status,
  },
  order: order
    ? {
        id: order.id,
        number: order.number,
        preferredPackDate: order.preferred_pack_date,
        preferredLoadDate: order.preferred_load_date,
        preferredDeliveryDate: order.preferred_delivery_date,
        origin: order.origin
          ? {
              id: order.origin.id,
              address1: order.origin.street1,
              address2: order.origin.street2,
              city: order.origin.locality,
              state: order.origin.region,
              postalCode: order.origin.postal_code,
              country: order.origin.country,
            }
          : emptyAddress,
        destination: order.destination
          ? {
              id: order.destination.id,
              address1: order.destination.street1,
              address2: order.destination.street2,
              city: order.destination.locality,
              state: order.destination.region,
              postalCode: order.destination.postal_code,
              country: order.destination.country,
            }
          : emptyAddress,
      }
    : {},
  customer: order.customer
    ? {
        id: order.customer.id,
        firstName: order.customer.first_name,
        lastName: order.customer.last_name,
      }
    : {
        id: '',
        firstName: '',
        lastName: '',
      },
});
