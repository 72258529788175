import { DEPOT_API_URL } from '../constants';
import { createRequest } from '../createRequest';
import { transformResponse } from './transformResponse';

export async function fetchSurveysByServiceId(serviceId: string) {
  const request = createRequest(
    `${DEPOT_API_URL}/surveys.json?filter[service_id]=${serviceId}&include=order,order.customer,order.origin,order.destination&fields[order]=number&fields[order.customer]=first_name,last_name`
  );

  try {
    const response = await fetch(request);
    const json = await response.json();
    if (response.ok) {
      const [survey] = json.data;
      return transformResponse(survey);
    } else {
      let message = 'Unknown Error';
      if (json.error) {
        message = json.error;
      }
      throw new Error(`Failed to fetch survey (${message})`);
    }
  } catch (error) {
    console.error(error);
  }
}
