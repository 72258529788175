import { useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import {
  Button,
  Divider,
  Drawer,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Stack,
} from '@mui/material';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HomeIcon from '@mui/icons-material/Home';
import Link from 'next/link';
import {
  useOrder,
  useOnlineStatus,
  useCustomer,
  useMediaMatches,
  useOpenVideoPopup,
  useSurvey,
} from '@/hooks';
import { ConfirmCompleteSurveyModal } from './ConfirmCompleteSurveyModal';
import { ConfirmStartSurveyModal } from './ConfirmStartSurveyModal';
import { WifiIndicator } from './WifiIndicator';
import { createSurvey } from '@/api/createSurvey';
import { updateSurvey } from '@/api/updateSurvey';
import { startSurveyService } from '@/api/startSurveyService';
import { useAppState } from '@/hooks/useAppState';
import { APP_STATES } from '@/constants/app';
import { ConfirmSaveSurveyModal } from './ConfirmSaveSurveyModal';
import { useSnackbar } from '@/components/common/Snackbar/useSnackbar';
import { useApp } from '@/hooks/useApp';
import { useAuth } from '@/services/Auth/useAuth';
import { useHasSurveyBeenStarted } from '@/hooks/useHasSurveyBeenStarted';
import { useSurveyType } from '@/hooks/useSurveyType';

export function AppBar() {
  const [app, setApp] = useApp();
  const { survey, setSurvey } = useSurvey();
  const [appState, setAppState] = useAppState();
  const { showSuccess, showError } = useSnackbar();
  const isEditing = appState === APP_STATES.EDITING;
  const openVideo = useOpenVideoPopup('/video', 'Survey Video');
  const { isSignedIn } = useAuth();
  const isOnline = useOnlineStatus();
  const customer = useCustomer();
  const order = useOrder();
  const matches = useMediaMatches();
  const [confirmStartOpen, setConfirmStartOpen] = useState(false);
  const [confirmCompleteOpen, setConfirmCompleteOpen] = useState(false);
  const [confirmSaveOpen, setConfirmSaveOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pendingSurveyRequest, setPendingSurveyRequest] = useState(false);
  const [pendingSurveyStart, setPendingSurveyStart] = useState(false);
  const hasSurveyBeenStarted = useHasSurveyBeenStarted();
  const { isVirtualSurvey, isOnsiteSurvey } = useSurveyType();

  const openConfirmStart = () => setConfirmStartOpen(true);
  const closeConfirmStart = () => setConfirmStartOpen(false);

  const openConfirmComplete = () => setConfirmCompleteOpen(true);
  const closeConfirmComplete = () => setConfirmCompleteOpen(false);

  const openConfirmSave = () => setConfirmSaveOpen(true);
  const closeConfirmSave = () => setConfirmSaveOpen(false);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  const startSurvey = async () => {
    setPendingSurveyStart(true);
    try {
      const response = await startSurveyService(survey.serviceId);
      if (response) {
        setApp({
          ...app,
          ...response,
        });
        showSuccess('Your survey has been started.');

        if (isVirtualSurvey) {
          openVideo();
        }
      }
    } catch (e) {
      showError(String(e));
    } finally {
      setPendingSurveyStart(false);
      closeConfirmStart();
    }
  };

  const completeSurvey = async () => {
    setPendingSurveyRequest(true);
    try {
      const response = await createSurvey(survey);
      if (response) {
        setApp({
          ...app,
          service: response.service,
        });
        setSurvey(response.survey);
        setAppState(APP_STATES.EDITING);
        showSuccess('Your survey is complete.');
      }
    } catch (e) {
      showError(String(e));
    } finally {
      setPendingSurveyRequest(false);
      closeConfirmComplete();
      closeDrawer();
    }
  };

  const saveSurvey = async () => {
    setPendingSurveyRequest(true);
    try {
      const response = await updateSurvey(survey);
      if (response) {
        setApp({
          ...app,
          service: response.service,
        });
        setSurvey(response.survey);
        showSuccess('Your survey has been saved.');
      }
    } catch (e) {
      showError(String(e));
    } finally {
      setPendingSurveyRequest(false);
      closeConfirmSave();
      closeDrawer();
    }
  };

  const signOut = () => {
    closeDrawer();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            data-testid="AppMenu"
            onClick={openDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>

          <Stack
            direction="row"
            alignItems="center"
            gap={2}
            sx={{ flexGrow: 1 }}
          >
            <Typography variant="h6">
              {order ? order.number : 'Survey'}
            </Typography>

            <>
              {matches.smUp && customer ? (
                <>
                  <span>-</span>
                  <Typography variant="h6" noWrap={true}>
                    {customer.firstName} {customer.lastName}
                  </Typography>
                </>
              ) : null}
            </>
          </Stack>

          {isOnline ? (
            <Button
              size="small"
              aria-label="Start Survey"
              disabled={isOnsiteSurvey && hasSurveyBeenStarted}
              onClick={hasSurveyBeenStarted ? openVideo : openConfirmStart}
              sx={{
                mr: 1,
                backgroundColor: 'white',
                border: '1px solid white',
                ':hover': {
                  color: 'white',
                },
              }}
            >
              {hasSurveyBeenStarted
                ? isVirtualSurvey
                  ? 'Open Survey'
                  : 'Survey In Progress'
                : 'Start Survey'}
            </Button>
          ) : null}

          <WifiIndicator />
        </Toolbar>
      </MuiAppBar>
      <Drawer
        open={drawerOpen}
        onClose={closeDrawer}
        sx={{
          p: 2,
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: '250px',
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="end"
          padding={2}
          sx={{ backgroundColor: 'primary.main' }}
        >
          <IconButton onClick={closeDrawer} sx={{ color: 'white' }}>
            <ChevronLeftIcon />
          </IconButton>
        </Stack>

        <Divider />

        <MenuList>
          <MenuItem component={Link} href="/" onClick={closeDrawer}>
            <ListItemIcon>
              <HomeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Survey" />
          </MenuItem>

          <MenuItem
            divider
            component={Link}
            href="/order"
            onClick={closeDrawer}
            sx={{ pb: 2 }}
          >
            <ListItemIcon>
              <DescriptionRoundedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Order" />
          </MenuItem>

          <MenuItem component={Button} onClick={signOut} sx={{ mt: 2 }}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText sx={{ textTransform: 'none' }} primary="Sign Out" />
          </MenuItem>
        </MenuList>

        <MenuItem sx={{ justifyContent: 'center', mt: 'auto', pb: 2 }}>
          {isEditing ? (
            <Button
              variant="contained"
              onClick={openConfirmSave}
              disabled={!isOnline || !isSignedIn}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Save Survey
            </Button>
          ) : (
            <Button
              variant="contained"
              color="success"
              onClick={openConfirmComplete}
              disabled={!isOnline || !isSignedIn}
              sx={{ whiteSpace: 'nowrap' }}
            >
              Complete Survey
            </Button>
          )}
        </MenuItem>
      </Drawer>

      <ConfirmStartSurveyModal
        open={confirmStartOpen}
        loading={pendingSurveyStart}
        onCancel={closeConfirmStart}
        onConfirm={startSurvey}
      />

      <ConfirmCompleteSurveyModal
        open={confirmCompleteOpen}
        loading={pendingSurveyRequest}
        onCancel={closeConfirmComplete}
        onConfirm={completeSurvey}
      />

      <ConfirmSaveSurveyModal
        open={confirmSaveOpen}
        loading={pendingSurveyRequest}
        onCancel={closeConfirmSave}
        onConfirm={saveSurvey}
      />
    </Box>
  );
}
