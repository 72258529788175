import { useAtom } from 'jotai';
import {
  CardContent,
  Paper,
  ListItem,
  Stack,
  Typography,
  List,
} from '@mui/material';
import {
  addRoom,
  removeRoom,
  updateRoom,
  getSegment,
  createSegment,
  removeSegment,
  updateSegment,
  removeItemsInRoom,
  removeItemsInSegment,
} from '@/utils';
import { Room, RoomListItem, Segment, SegmentData } from '@/types';
import { useMediaMatches, useSurvey, useSurveyReport } from '@/hooks';
import { selectedSegmentIdAtom, segmentItemsAtom } from '@/store';
import {
  RoomList,
  AddRoomButton,
  SegmentsList,
  AddSegmentButton,
  SurveyTotals,
  SurveyTotalsFooter,
} from './components';
import { AppLayout } from '@/components/layouts';
import { filterDeletedItems } from '@/utils/filterDeletedItems';
import { OBJECT_METHOD } from '@/constants/objectMethods';

export function SurveyPage() {
  const { segments, setSegments, rooms, setItems, setRooms } = useSurvey();
  const [selectedSegmentId, setSelectedSegmentId] = useAtom(
    selectedSegmentIdAtom
  );
  const [allSegmentItems] = useAtom(segmentItemsAtom);
  const segmentItems = filterDeletedItems(allSegmentItems);
  const surveyReport = useSurveyReport();
  const matches = useMediaMatches();

  const handleAddSegment = (segment: SegmentData) => {
    const existing = getSegment(segments, segment);
    if (existing) {
      setSelectedSegmentId(existing.id);
    } else {
      const newSegment = createSegment(segment);
      setSegments((prevSegments) => [...prevSegments, newSegment]);
      setSelectedSegmentId(newSegment.id as string);
    }
  };

  const handleSegmentClicked = (segment: Segment) => {
    setSelectedSegmentId(segment.id);
  };

  const onEditSegment = (segment: Segment) => {
    setSegments((prevSegments) => updateSegment(prevSegments, segment));
  };

  const onRemoveSegment = (segment: Segment) => {
    setItems((prevItems) => removeItemsInSegment(prevItems, segment.id));

    const updatedSegments = removeSegment(segments, segment);
    setSegments(updatedSegments);

    const displayedSegments = updatedSegments.filter(
      (segment) => segment._method !== OBJECT_METHOD.DESTROY
    );
    setSelectedSegmentId(displayedSegments[0]?.id);
  };

  const onRemoveRoom = (room: Room) => {
    setItems((prevItems) => removeItemsInRoom(prevItems, room.id));
    setRooms((prevRooms) => removeRoom(prevRooms, room));
  };

  const onAddRoom = (room: RoomListItem) => {
    setRooms((prevRooms) => addRoom(prevRooms, room));
  };

  const onEditRoom = (room: Room) => {
    setRooms((prevRooms) => updateRoom(prevRooms, room));
  };

  return (
    <Paper sx={{ height: 1 }}>
      <Stack height={1}>
        <List>
          <ListItem>
            <Stack direction="row" justifyContent="space-between" width={1}>
              <Typography variant="h6">
                {surveyReport.segmentItemsTotal.going} Surveyed Items
              </Typography>

              <AddRoomButton onAddRoom={onAddRoom}>
                {matches.xs ? 'Room' : 'Add Room'}
              </AddRoomButton>
            </Stack>
          </ListItem>
        </List>

        <CardContent>
          <Stack direction="row" gap={2} flexWrap="wrap">
            <SegmentsList
              segments={segments}
              itemsTotal={surveyReport.segmentItemsTotal.going}
              currentSegmentId={selectedSegmentId}
              onSegmentClicked={handleSegmentClicked}
              onEditSegment={onEditSegment}
              onRemoveSegment={onRemoveSegment}
            />

            <AddSegmentButton onAddSegment={handleAddSegment}>
              {matches.xs ? 'Segment' : 'New Segment'}
            </AddSegmentButton>
          </Stack>
        </CardContent>

        <CardContent>
          <SurveyTotals items={segmentItems} />
        </CardContent>

        <div style={{ flex: 1, height: 0 }}>
          <RoomList
            rooms={rooms}
            items={segmentItems}
            onAddRoom={onAddRoom}
            onRemoveRoom={onRemoveRoom}
            onEditRoom={onEditRoom}
          />
        </div>

        <CardContent>
          <SurveyTotalsFooter
            segmentTotals={surveyReport.segmentItemsTotal}
            itemsTotals={surveyReport.itemsTotal}
            proGearTotals={surveyReport.proGearItemsTotal}
            weightFactor={surveyReport.weightFactor}
          />
        </CardContent>
      </Stack>
    </Paper>
  );
}

SurveyPage.getLayout = AppLayout;
