import { ReactNode } from 'react';
import { OutlinedAddButton } from '@/components/common/Button';
import { SegmentData } from '@/types';
import { AddSegmentMenu } from './AddSegmentMenu';
import { usePopupState } from '@/hooks';

interface AddSegmentButtonProps {
  onAddSegment: (segment: SegmentData) => void;
  children: ReactNode;
}

export function AddSegmentButton({
  onAddSegment,
  children,
}: AddSegmentButtonProps) {
  const { popupAnchorEl, isPopupOpen, openPopup, closePopup } = usePopupState();

  const handleOnAddSegment = (segment: SegmentData) => {
    closePopup();
    onAddSegment(segment);
  };

  return (
    <>
      <OutlinedAddButton onClick={openPopup}>{children}</OutlinedAddButton>
      <AddSegmentMenu
        anchorEl={popupAnchorEl}
        open={isPopupOpen}
        onClose={closePopup}
        onCreate={handleOnAddSegment}
      />
    </>
  );
}
