import { ReactNode } from 'react';
import { Paper, CardContent, Typography, CardHeader } from '@mui/material';
import { Stack } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import { useCustomer, useOrder } from '@/hooks';
import { AppLayout } from '../layouts';

const Title = ({ children }: { children: ReactNode }) => (
  <Typography variant="body1">{children}</Typography>
);

const SubText = ({ children }: { children: ReactNode }) => (
  // Needs to be a span otherwise you get the error:
  // <div> cannot appear as a descendant of <p>.
  <Typography variant="body2" component="span">
    {children}
  </Typography>
);

export function OrderPage() {
  const { firstName, lastName } = useCustomer();
  const {
    origin,
    destination,
    preferredPackDate,
    preferredLoadDate,
    preferredDeliveryDate,
  } = useOrder();

  return (
    <Stack gap={2} padding={{ xs: 2, md: 0 }}>
      <Stack direction="row" flexWrap="wrap" gap={1}>
        <Typography variant="h4">{firstName}</Typography>
        <Typography variant="h4">{lastName}</Typography>
      </Stack>

      <Paper>
        <CardHeader title="Addresses" />

        <CardContent>
          <Grid container rowGap={3} columns={{ xs: 6, lg: 5 }}>
            <Grid xs={6} sm={3} lg={1}>
              <Stack gap="10px">
                <Title>Origin</Title>
                <SubText>
                  <div>{origin?.address1}</div>
                  <div>
                    {origin?.city} {origin?.state}, {origin?.postalCode}
                  </div>
                </SubText>
              </Stack>
            </Grid>

            <Grid xs={6} sm={3} lg={1}>
              <Stack gap="10px">
                <Title>Destination</Title>
                <SubText>
                  <div>{destination?.address1}</div>
                  <div>
                    {destination?.city} {destination?.state},{' '}
                    {destination?.postalCode}
                  </div>
                </SubText>
              </Stack>
            </Grid>
          </Grid>
        </CardContent>
      </Paper>

      <Paper>
        <CardHeader title="Dates" />

        <CardContent>
          <Stack
            direction="row"
            justifyContent="space-between"
            maxWidth={350}
            flexWrap="wrap"
          >
            <Stack gap="10px">
              <Title>Pack</Title>
              <SubText>{preferredPackDate}</SubText>
            </Stack>

            <Stack gap="10px">
              <Title>Load</Title>
              <SubText>{preferredLoadDate}</SubText>
            </Stack>

            <Stack gap="10px">
              <Title>Delivery</Title>
              <SubText>{preferredDeliveryDate}</SubText>
            </Stack>
          </Stack>
        </CardContent>
      </Paper>
    </Stack>
  );
}

OrderPage.getLayout = AppLayout;
