import { DEPOT_API_URL } from '../constants';
import { transformResponse } from './transformResponse';
import { createRequest } from '../createRequest';

export async function fetchSurveyService(id: string) {
  const request = createRequest(
    `${DEPOT_API_URL}/services/${id}.json?include=order,order.customer,order.origin,order.destination`
  );

  try {
    const response = await fetch(request);
    const json = await response.json();
    if (response.ok) {
      return transformResponse(json.data);
    } else {
      let message = 'Unknown Error';
      if (json.error) {
        message = json.error;
      }
      throw new Error(`Failed to fetch survey service (${message})`);
    }
  } catch (error) {
    console.error(error);
  }
}
