import Popover from '@mui/material/Popover';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Button } from '@/components/common';
import { Segment } from '@/types';
import {
  SelectChangeEvent,
  Stack,
  Container,
  Typography,
  ListItem,
  Grid,
  InputLabel,
  ButtonProps,
  Box,
} from '@mui/material';
import { useState } from 'react';
import {
  SEGMENT_TYPE,
  SEGMENT_FROM,
  SEGMENT_FROMS,
  SEGMENT_TO,
  SEGMENT_TOS,
} from '@/constants';
import { isSameSegment } from '@/utils/segment';

const fromDefault = SEGMENT_FROM.MP;
const toDefault = SEGMENT_TO.MD;
const segmentTypeDefault = SEGMENT_TYPE.LAND;

interface EditSegmentMenuProps {
  segments: Array<Segment>;
  segment: Segment;
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  onEdit: (segment: Segment) => void;
}

interface SegmentButtonProps extends ButtonProps {
  isActive: boolean;
}

const SegmentButton = ({
  isActive,
  children,
  ...props
}: SegmentButtonProps) => (
  <Button
    {...props}
    disableElevation
    size="small"
    role="radio"
    disabled={isActive}
    className={isActive ? 'active' : ''}
    sx={[
      {
        width: 1,
        background: '#f2f4f6',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      {
        '&.active': {
          backgroundColor: 'primary.main',
          color: 'common.white',
        },
        '&:hover': {
          color: 'common.white',
        },
      },
    ]}
  >
    <Typography variant="subtitle2">{children}</Typography>
  </Button>
);

export function EditSegmentMenu({
  segments,
  segment,
  anchorEl,
  open,
  onClose,
  onEdit,
}: EditSegmentMenuProps) {
  const [from, setFrom] = useState<string>(segment.from);
  const [to, setTo] = useState<string>(segment.to);
  const [segmentType, setSegmentType] = useState<string>(segment.type);
  const [error, setError] = useState('');

  const resetDefaults = () => {
    setFrom(fromDefault);
    setTo(toDefault);
    setSegmentType(segmentTypeDefault);
  };

  const handleClose = () => {
    resetDefaults();
    onClose();
  };

  const handleSubmit = () => {
    const newSegment = {
      id: segment.id,
      to,
      from,
      type: segmentType,
    } as Segment;
    const existing = segments.find((segment) =>
      isSameSegment(segment, newSegment)
    );
    if (existing) {
      setError('Segment already exists.');
    } else {
      setError('');
      resetDefaults();
      onEdit(newSegment);
    }
  };

  const handleFromChange = (event: SelectChangeEvent<unknown>) => {
    setFrom(event.target.value as string);
  };

  const handleToChange = (event: SelectChangeEvent<unknown>) => {
    setTo(event.target.value as string);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Container sx={{ py: 2, width: 275 }}>
        <ListItem>
          <FormControl size="small" fullWidth>
            <InputLabel id="segment-from-type">From Type</InputLabel>
            <Select
              labelId="from-type"
              id="from-type"
              value={from}
              label="From Type"
              onChange={handleFromChange}
            >
              {SEGMENT_FROMS.map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <FormControl size="small" fullWidth>
            <InputLabel id="segment-from-type">To Type</InputLabel>
            <Select
              labelId="to-type"
              id="to-type"
              value={to}
              label="To Type"
              onChange={handleToChange}
            >
              {SEGMENT_TOS.map((type) => (
                <MenuItem key={type} value={type}>
                  <Typography>{type}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <Grid container spacing={0.5} padding={1} role="radiogroup">
          <Grid item xs={6}>
            <SegmentButton
              isActive={segmentType === SEGMENT_TYPE.LAND}
              onClick={() => setSegmentType(SEGMENT_TYPE.LAND)}
            >
              Land
            </SegmentButton>
          </Grid>
          <Grid item xs={6}>
            <SegmentButton
              isActive={segmentType === SEGMENT_TYPE.SEA}
              onClick={() => setSegmentType(SEGMENT_TYPE.SEA)}
            >
              Sea
            </SegmentButton>
          </Grid>
          <Grid item xs={6}>
            <SegmentButton
              isActive={segmentType === SEGMENT_TYPE.AIR}
              onClick={() => setSegmentType(SEGMENT_TYPE.AIR)}
            >
              Air
            </SegmentButton>
          </Grid>
          <Grid item xs={6}>
            <SegmentButton
              isActive={segmentType === SEGMENT_TYPE.PERM}
              onClick={() => setSegmentType(SEGMENT_TYPE.PERM)}
            >
              Perm
            </SegmentButton>
          </Grid>
        </Grid>

        <Box>
          <Typography color="error" textAlign="center">
            {error ? error : ''}
          </Typography>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          padding={1}
        >
          <Button
            sx={{ gridColumnStart: 2, gridColumnEnd: 4 }}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Stack>
      </Container>
    </Popover>
  );
}
